import { Component, Vue } from 'vue-property-decorator';
import {
  ImageSizes,
  ImageSize,
  ImageSizesQuoteThumbnail,
} from '@/common/interfaces/SizeBreakpoints';
import { QuoteRichBlockViewModel } from './QuoteRichModule.types';
import { getComponentProps } from '@/utils/vueUtils';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
import { getResizeImage } from '@/utils/commonUtils';

const QuoteRichModuleProps = Vue.extend({
  props: getComponentProps<QuoteRichBlockViewModel>({
    Quote:
      'My family took the first guests to Alaska over 60 years ago, and in that time lorem ipsum dolor placentar',
    SubQuote: 'SVEN LINDBLAD, MAY 2019',
    HeadshotImage: { Url: getResizeImage('thumbnail-sven.jpg'), Alt: '' },
    BackgroundImage: { Url: getResizeImage('quote-rich-bg.jpg'), Alt: '' },
    Cta: { Title: 'View our story', Link: '#' },
  }),
});

@Component({
  components: {
    ResponsiveImage,
    CtaButton,
  },
})
export default class QuoteRichModule extends QuoteRichModuleProps {
  imageSizesThumbnail: ImageSizes = ImageSizesQuoteThumbnail;

  imageSizesBackground: ImageSizes = {
    def: new ImageSize([560, 667]),
    sizes: {
      sm: new ImageSize([767, 432]),
      md: new ImageSize([991, 432]),
      lg: new ImageSize([1199, 618]),
      xl: new ImageSize([1500, 618]),
    },
  };
}
